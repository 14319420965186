import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/episode-layout.js";
import { Link } from 'gatsby';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "ep8---jhey-tompkins-on-making-learning-development-fun"
    }}>{`Ep8 - Jhey Tompkins: On making learning development fun`}</h1>
    <div className="soundcloud-iframe">
    <iframe className="mb-0" width="100%" height="20" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/938588143&color=%23fc4056&inverse=true&auto_play=false&show_user=true"></iframe>
    </div>
    <Link className="link-button mr-5" to="/transcripts/ep8-jhey-tompkins/" mdxType="Link">Transcript</Link>
    <a className="link-button" href="https://youtu.be/TR6_3hxD2EM">Watch this talk</a>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`On this episode, Jhey talks about his journey into tech, how it wasn't his first choice and how much he disliked the course at first. Code was something that he did to be able to have time to do other things, then one day he did a bit of frontend and feel in love with code.`}</p>
    <p>{`We talk about a lot of different subjects, how Jhey left a well-paid job because he didn't identify with the job, how he decided to create his own company to do some freelancing/consulting and how hard it was to get things started.`}</p>
    <p>{`We also talk extensively about streaming, CSS animations and much more!`}</p>
    <h2 {...{
      "id": "guest-information"
    }}>{`Guest Information`}</h2>
    <div className="episode-card">
    <img src="https://landingintech.com/jhey-tompkins.jpg" alt="Jhey Tompkins" className="guest-avatar" />
    <div className="mx-8 pt-5">
        <p className="episode-title text-xl">Jhey Tompkins</p>
        <p>Creative Developer</p>
        <p>
            <a href="https://jhey.dev"> Website</a> | <a href="https://twitter.com/jh3yy"> Twitter</a> | <a href="https://github.com/jh3y"> Github</a>
        </p>
    </div>
    </div>
    <h2 {...{
      "id": "resources-and-links"
    }}>{`Resources and Links`}</h2>
    <p>{`Through the talk we have mentioned a lot of interesting projects, books, videos and other resources, you can find all about them in this section.`}</p>
    <p><em parentName="p"><strong parentName="em">{`Note`}</strong>{`: Any link to amazon is an affiliate link which means if you buy something from the link, it will not cost you more but we get paid a percentage from amazon which helps with the expenses of running the show.`}</em></p>
    <h3 {...{
      "id": "articles"
    }}>{`Articles`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://css-tricks.com/css-in-3d-learning-to-think-in-cubes-instead-of-boxes/"
        }}>{`CSS in 3D: Learning to think in cubes instead of boxes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.smashingmagazine.com/2020/07/thoughts-feelings-creative-wellness/"
        }}>{`It's good to talk: Thoughts and feelings on creative wellness`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.smashingmagazine.com/2020/11/playfulness-code-supercharge-fun-learning/"
        }}>{`Playfulness in code: Supercharge your learning by having fun`}</a></li>
    </ul>
    <h3 {...{
      "id": "open-source"
    }}>{`Open Source`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/jh3y/pxl"
        }}>{`Pxl`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/jh3y/vincent-van-git"
        }}>{`Vincent van git`}</a></li>
    </ul>
    <h3 {...{
      "id": "other-resources"
    }}>{`Other Resources`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://twitter.com/hexagoncircle"
        }}>{`Twitter: Ryan Mulligan`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://codepen.io/jh3y"
        }}>{`Jhey's codepen`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.twitch.tv/jh3yy"
        }}>{`Jhey's Twitch channel`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      